.transparent-modal {
    --background: rgb(0, 0, 0, 0);
    --backdrop-opacity: 0%
  }
  
  .transparent-modal .ion-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  :root {
    --ion-color-rose: #831843;
    --ion-color-rose-rgb: 131, 24, 67;
    --ion-color-rose-contrast: #ffffff;
    --ion-color-rose-contrast-rgb: 255, 255, 255;
    --ion-color-rose-shade: #73153b;
    --ion-color-rose-tint: #8f2f56;
  
    --ion-text-color: #2492ff;
    --ion-text-color-rgb: 36,146,255;
  
    --ion-color-step-50: #f4faff;
    --ion-color-step-100: #e9f4ff;
    --ion-color-step-150: #deefff;
    --ion-color-step-200: #d3e9ff;
    --ion-color-step-250: #c8e4ff;
    --ion-color-step-300: #bddeff;
    --ion-color-step-350: #b2d9ff;
    --ion-color-step-400: #a7d3ff;
    --ion-color-step-450: #9cceff;
    --ion-color-step-500: #92c9ff;
    --ion-color-step-550: #87c3ff;
    --ion-color-step-600: #7cbeff;
    --ion-color-step-650: #71b8ff;
    --ion-color-step-700: #66b3ff;
    --ion-color-step-750: #5badff;
    --ion-color-step-800: #50a8ff;
    --ion-color-step-850: #45a2ff;
    --ion-color-step-900: #3a9dff;
    --ion-color-step-950: #2f97ff;
  }

  :root {
    --ion-color-my-blue-desk: #2492ff;
    --ion-color-my-blue-desk-rgb: 36,146,255;
    --ion-color-my-blue-desk-contrast: #ffffff;
    --ion-color-my-blue-desk-contrast-rgb: 255,255,255;
    --ion-color-my-blue-desk-shade: #2080e0;
    --ion-color-my-blue-desk-tint: #3a9dff;
  }
  
  .ion-color-my-blue-desk {
    --ion-color-base: var(--ion-color-my-blue-desk);
    --ion-color-base-rgb: var(--ion-color-my-blue-desk-rgb);
    --ion-color-contrast: var(--ion-color-my-blue-desk-contrast);
    --ion-color-contrast-rgb: var(--ion-color-my-blue-desk-contrast-rgb);
    --ion-color-shade: var(--ion-color-my-blue-desk-shade);
    --ion-color-tint: var(--ion-color-my-blue-desk-tint);
  }
  
  ion-datetime {
    --background: #c8e4ff;
    --background-rgb: 200, 228, 255;
  
    border-radius: 16px;
    box-shadow: rgba(var(--ion-color-my-blue-desk), 0.3) 0px 10px 15px -3px;
  }