  .parent-button {
    --border-color: #731773;
    --color: #731773;
  }

  .student-button {
    --border-color: #E7E717;
    --color: #E7E717;
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 5px;
  }
  
  .circle-image-container {
    width: 60px; /* Adjust the width as needed */
    height: 60px; /* Adjust the height as needed */
    border-radius: 5%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .light-mode-image,
  .dark-mode-image {
    width: 119.5%;
    height: auto;
    position: absolute;
  }
  
  @media (prefers-color-scheme: light) {
    .light-mode-image {
      display: block;
    }
    .dark-mode-image {
      display: none;
    }
  }
  
  @media (prefers-color-scheme: dark) {
    .light-mode-image {
      display: none;
    }
    .dark-mode-image {
      display: block;
    }
  }  
  
  .image-container {
    display: flex;
    justify-content: center;
    gap: 15px; /* Adjust the value to increase or decrease the space between the images */
  }

  .centered-text {
    text-align: center;
  }
  
  .centered-text-margin {
    text-align: center;
    margin-bottom: 20px
  }
  
  @media screen and (min-width: 0\0) {
    .fc {
      display: none;
    }
    .fc * {
      display: none;
    }
    .fc, .fc * {
      display: block;
    }
  }
  